<script setup>
import { ref, defineProps } from "vue"
import { useFloating, offset, shift, autoUpdate, flip } from "@floating-ui/vue"
import { useClickOutside } from "@/composition/click-outside"

const PLACEMENT = "right-end"
const OFFSET = 10

/*
* Array<string>
*/
defineProps({
  actions: {
    type: Array,
    required: true,
  },
})

const anchor = ref(null)
const floating = ref(null)

const { clickOutsideRoot, isOpen, toggle } = useClickOutside()
const { floatingStyles } = useFloating(anchor, floating, {
  placement: PLACEMENT,
  middleware: [offset(OFFSET), shift(), flip()],
  whileElementsMounted: autoUpdate,
})

</script>

<template>
  <div
    ref="clickOutsideRoot"
    class="ui-table-item-actions"
  >
    <button
      @click="() => toggle()"
      ref="anchor"
      class="ui-table-item-actions__anchor"
    >
      <v-icon>mdi-dots-horizontal</v-icon>
    </button>

    <ul
      v-if="isOpen"
      ref="floating"
      :style="floatingStyles"
      class="ui-table-item-actions__floating"
    >
      <li
        v-for="(action, i) of actions"
        :key="`${action}-${i}`"
        :class="{
          'ui-table-item-actions__action': true,
          [`ui-table-item-actions__action--${action}`]: action,
        }"
      >
        <slot :name="`action(${action})`" />
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
$delete-base: #b82519;
.ui-table-item-actions {
  display: inline-block;

  &__floating {
    max-width: 250px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
    list-style-type: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  &__action {
    display: flex;
    justify-content: flex-start;
    //padding: .875rem 1rem;
    cursor: pointer;
    min-height: 32px;

    &:hover {
      background-color: #e0e0e0;
    }

    &--delete:hover {
      color: white;
      background-color: $delete-base;
    }
  }
}
</style>

