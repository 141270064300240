<template>
  <div style="display: contents;">
    <XDataTable
      title="Explorer Tests"
      class="explorer-tests-view"
      :headers="headers"
      :items-request="getExplorerTestStatus"
      :items-request-params="itemsRequestParams"
      :select-actions="selectActions"
      table-name="Explorer Tests"
      :ready-to-load="Boolean(explorerIdComputed)"
      @refresh="() => refreshStatusesCounts()"
      @sorting-change="(header) => updateSortingData(header.value)"
      no-count
    >
      <template #before-range>
        <XSelect
          v-model="explorerIdComputed"
          label="Explorer"
          :items="explorerList"
          item-value="id"
          item-text="name"
          required
          @input="(expId) => handleExplorerUpdate(expId)"
        />
      </template>

      <template #time-range-slot>
        <DateTimeRange
          :value="range"
          @input="(newRange) => handleRangeUpdate(newRange)"
        />
      </template>

      <template #search-input-slot>
        <XTextField
          class="explorer-tests-view__search-text-field"
          :value="search"
          clearable
          label="Search"
          append-icon="mdi-magnify"
          @input="(searchStr) => handleSearchUpdate(searchStr)"
        />
      </template>

      <template #above-table>
        <div class="explorer-tests-view__status-bar">
          <XBtn
            class="explorer-tests-view__total-button"
            :text="statusTotal.text"
            height="25"
            :color="statusesFilter.length ? `${statusTotal.color} lighten-3` : `${statusTotal.color}`"
            elevation="0"
            @click="() => selectTotalStatuses()"
          />

          <SplitButton
            class="explorer-tests-view__statuses-split-btn"
            :value="statusesFilter"
            :values="statusesCounts"
            :splits="statusesRepr"
            :total="statusesCounts?.total || 0"
            @input="(statusesList) => updateTestsViewWithStatuses(statusesList)"
            multiple
          />
        </div>
      </template>

      <template #[`item.testCase.name`]="{ value, row: item }">
        <ClickableText2
          :text="value"
          :to="getTestQueueRoute(item.id)"
        />
      </template>

      <template #[`item.owner`]="{ value }">
        <v-tooltip top>
          <template #activator="{ attrs, on }">
            <span class="explorer-tests-view__owner-first-name" v-bind="attrs" v-on="on">
              {{ value.firstName }}
            </span>
          </template>

          <span>
            {{ `${value.firstName} ${value.lastName}` }}
          </span>
        </v-tooltip>
      </template>

      <template #[`item.subscriber`]="{ value }">
        <ClickableText
          :text="value.name"
          @click="() => openExplorerDialog({ id: value.id })"
        />

        {{ value.alias ? ` (${value.alias})` : '' }}
      </template>

      <template #[`item.status`]="{ value }">
        <div class="explorer-test-view__status">
          <v-icon :color="TEST_STATUS_COLORS[value]">
            {{ TEST_STATUS_ICONS[value] }}
          </v-icon>

          {{ TEST_STATUS_NAMES[value] }}
        </div>
      </template>

      <template #pagination-slot="{ metadata }">
        <PaginationBlock
          :total-count="metadata.totalCount || 0"
          :current-page="page"
          :items-per-page="metadata.perPage || 0"
          @update:current-page="(pageN) => setPageN(pageN)"
        />
      </template>

      <template #items-per-page-slot>
        <XSelect
          class="explorer-test-view__items-per-page-select"
          :items="ITEMS_PER_PAGE_OPTIONS_DEFAULT"
          :value="itemsPerPage"
          @input="(_itemsPerPage) => updateItemsPerPage(_itemsPerPage)"
          label="Items per page"
          required
          :autocomplete="false"
        />
      </template>
    </XDataTable>

    <LoadingDialog v-model="loading" />
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, provide, inject, computed } from "vue"
import XDataTable from '@/components/basic/XDataTable.vue';
import XSelect from '@/components/basic/XSelect.vue';
import ClickableText2 from '@/components/basic/ClickableText2.vue';
import {shaKey} from '@/js/helper';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import SplitButton from "@/components/basic/SplitButton.vue";
import XBtn from '@/components/basic/XBtn.vue';
import DateTimeRange from '@/components/basic/DateTimeRange.vue';
import XTextField from '@/components/basic/XTextField.vue';
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import testCaseTypes from '@/cfg/testCaseTypes.json';
import explorerStatusService from '@/js/services/ExplorerStatusService';
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import cockpitTestStatusService from '@/js/services/CockpitTestStatusService';
import { unixToDateTimeString, secondsToDuration2 } from '@/js/general';
import { useStatusBarSelectWithRouter } from "@/composition/filtering-components/use-status-bar-select"
import { useTimeRangeSelectWithRouter } from "@/composition/filtering-components/use-time-range-select"
import { useSearchInputWithRouter, MIN_SEARCH_LENGTH } from "@/composition/filtering-components/use-search-input-w-router"
import { useTableSettingsWithRouter, ITEMS_PER_PAGE_OPTIONS_DEFAULT } from "@/composition/tables/use-table-settings"
import { usePaginationWithRouter } from "@/composition/filtering-components/use-pagination-w-router"
import { useTableSortingWithRouter } from "@/composition/filtering-components/use-sorting-w-router"
import { useRoute } from "vue-router/composables"
import { debounce } from "lodash-es"
import { useZipFileDownloader } from "@/composition/zip-files/use-zip-file-downloader";

const TEST_STATUS_NAMES = {
  0: 'Setting up',
  1: 'Running',
  2: 'OK',
  3: 'Result Warning',
  4: 'Result Error',
  5: 'Aborted',
  6: 'Test Timeout',
  7: 'Processing Warning',
  8: 'Processing Error',
}

const TEST_STATUS_COLORS = {
  0: 'testStatusRunning',
  1: 'testStatusRunning',
  2: 'ok',
  3: 'testStatusWarning',
  4: 'testStatusError',
  5: 'testStatusAborted',
  6: 'testStatusMaxRuntime',
  7: 'testStatusRuntimeWarning',
  8: 'testStatusRuntimeError',
}

const TEST_STATUS_ICONS = {
  0: 'mdi-timer-sand',
  1: 'mdi-timer-sand',
  2: 'mdi-check-circle',
  3: 'mdi-alert',
  4: 'mdi-alert-box',
  5: 'mdi-close-octagon-outline',
  6: 'mdi-progress-clock',
  7: 'mdi-alert',
  8: 'mdi-alert-box',
}

const EXPLORER_ID_PARAM = "explorerId"
const TABLE_SETTINGS_KEY = "explorer-tests-table-settings"
const TABLE_SETTINGS = {
  itemsPerPage: 25,
}

// Termporary solution
// TODO: remove this function after using new table components
function getExplorerTestStatus(
  explorerId,
  status,
  currentProject,
  search,
  itemsPerPage,
  sortBy,
  descending,
  rangeFrom,
  rangeTo,
  page,
  params,
  then,
  error
) {
  params["search"] = search
  params["items-per-page"] = itemsPerPage
  params["sortBy"] = sortBy
  params["descending"] = descending
  params["from"] = rangeFrom
  params["to"] = rangeTo
  params["page"] = page
  cockpitTestStatusService.getExplorerTestStatus(explorerId, status, currentProject, params, then, error)
}

export default defineComponent({
  name: 'ExplorerTestsView',

  components: {
    XTextField,
    SplitButton,
    LoadingDialog,
    ClickableText2,
    XSelect,
    XBtn,
    XDataTable,
    DateTimeRange,
    PaginationBlock,
  },

  setup() {
    const { downloadZipFile } = useZipFileDownloader();
    const {
      openDialog
    } = inject("DialogsRoot")
    const route = useRoute()
    const {
      search,
      searchAsReqParam,
    } = useSearchInputWithRouter()
    const explorerId = ref(0)
    const explorerList = ref([])
    const {
      statusesCounts,
      statusesFilter,
      statusesRepr,
      statusTotal,
      statusesAsParamString,
      selectTotalStatuses,
    } = useStatusBarSelectWithRouter({ multiple: true })
    const {
      range,
      rangeAsReqParams,
    } = useTimeRangeSelectWithRouter()

    const {
      sortingData,
      updateSortingData,
    } = useTableSortingWithRouter()

    const {
      page,
      setPageN
    } = usePaginationWithRouter()

    const {
      settings: tableSettings,
      updateItemsPerPage: updItmsPerPage,
    } = useTableSettingsWithRouter({ key: TABLE_SETTINGS_KEY, defaultSettings: { ...TABLE_SETTINGS } })
    const itemsPerPageFromUrl = ref(route.query.itemsPerPage ? parseInt(`${route.query.itemsPerPage}`) : null)
    /**
     * @param {number} numberOfItems
     */
    const updateItemsPerPage = (numberOfItems) => {
      updItmsPerPage(numberOfItems)
      if (itemsPerPageFromUrl.value) {
        itemsPerPageFromUrl.value = null
      }
    }
    const itemsPerPage = computed(() => {
      if (itemsPerPageFromUrl.value) {
        return itemsPerPageFromUrl.value
      }
      return tableSettings.value.itemsPerPage
    })

    const getExplorerTestStatusCounts = async () => {
      const params = {
        to: rangeAsReqParams.value.to,
        from: rangeAsReqParams.value.from,
        search: search.value
      }

      try {
        return await cockpitTestStatusService.getExplorerTestStatusCounts(explorerId.value, true, params)
      } catch (error) {
        console.error("[getExplorerTestStatusCounts]", error)
      }
    }

    const refreshStatusesCounts = async () => {
      if (!explorerId.value) return

      statusesCounts.value = await getExplorerTestStatusCounts()
    }

    onBeforeMount(async () => {
      explorerId.value = parseInt(`${route.query[EXPLORER_ID_PARAM] || 0}`)

      await refreshStatusesCounts()
    })

    provide("x-data-table-refactoring", {
      sortingData,
    })

    return {
      openDialog,
      range,
      setPageN,
      rangeAsReqParams,
      search,
      explorerId,
      statusesCounts,
      statusesFilter,
      statusesRepr,
      statusTotal,
      statusesAsParamString,
      selectTotalStatuses,
      explorerList,
      refreshStatusesCounts,
      searchAsReqParam,
      getExplorerTestStatusCounts,
      getExplorerTestStatus,
      tableSettings,
      itemsPerPage,
      sortingData,
      updateItemsPerPage,
      updateSortingData,
      page,
      downloadZipFile,

      TEST_STATUS_NAMES,
      TEST_STATUS_COLORS,
      TEST_STATUS_ICONS,
      ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    }
  },
  data() {
    return {
      headers: Object.freeze([
        {
          text: 'Test Name',
          value: 'testCase.name',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          width: 100,
          formatter: value => testCaseTypes[value],
        },
        {
          text: 'Explorer',
          value: 'subscriber',
          width: 200,
          sortable: true,
        },
        {
          text: 'Owner',
          value: 'owner',
          width: 120,
        },
        {
          text: 'Start Time',
          value: 'startTime',
          formatter: value => unixToDateTimeString(value),
          sortable: true,
        },
        {
          text: 'End Time',
          value: 'endTime',
          formatter: value => value ? unixToDateTimeString(value) : '-',
          sortable: true,
        },
        {
          text: 'Duration',
          value: 'duration',
          formatter: (_, row) => {
            let endTime = row['endTime'];
            if (!endTime) endTime = Math.trunc(new Date().getTime() / 1000);
            return secondsToDuration2(endTime - row['startTime'], { useColonFormat: true })
          },
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          width: 183,
          sortable: true,
        },
        {
          text: 'Result Info',
          value: 'resultInfo',
          sortable: true,
        },
        {
          text: 'Audit',
          value: 'audit',
        }
      ]),
      selectActions: Object.freeze([
        {
          icon: 'mdi-stop',
          text: 'Stop Selected',
          iconColor: "red",
          click: (selectedItems) => {
            selectedItems = Object.keys(selectedItems).filter(key => selectedItems[key]);
            testCaseInfoService.stopTests(selectedItems);
          },
        },

        {
          icon: 'mdi-folder-zip',
          text: 'Detailed Report (zip)',
          click: async (selectedItems) => {
            this.loading = true;

            try {
              await this.downloadZipFile(selectedItems);
            } finally {
              this.loading = false;
            }
          }
        },
      ]),
      loading: false,
    };
  },

  created() {
    explorerStatusService.getExplorerList((explorerList) => {
      this.explorerList = explorerList;
    });
  },

  computed: {
    explorerIdComputed: {
      get() {
        return this.explorerId
      },
      /**
      * @param {number} value
      */
      set(value) {
        const newQuery = { ...this.$route.query };

        delete newQuery[EXPLORER_ID_PARAM];

        if (value) {
          newQuery[EXPLORER_ID_PARAM] = value.toString();
        }

        this.$router.replace({ path: this.$route.path, query: newQuery })
          .catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              throw err
            }
          })

        this.explorerId = value
      }
    },

    itemsRequestParams() {
      return [
        this.explorerIdComputed,
        this.statusesAsParamString,
        true, // currentProject
        this.searchAsReqParam,
        this.itemsPerPage,
        this.sortingData.sortBy,
        this.sortingData.descending,
        this.rangeAsReqParams.from,
        this.rangeAsReqParams.to,
        this.page,
      ];
    },
  },

  methods: {
    /**
     * @param {number} expId
     */
    async handleExplorerUpdate(expId) {
      this.explorerIdComputed = expId;
      this.setPageN(1)
      await this.refreshStatusesCounts();
    },

    async handleRangeUpdate(newRange) {
      this.range = newRange;
      this.setPageN(1)
      await this.refreshStatusesCounts();
    },

    handleSearchUpdate: debounce(async function(searchStr) {
      if ((searchStr.length && searchStr.length < MIN_SEARCH_LENGTH) || this.search?.length < MIN_SEARCH_LENGTH && !searchStr.length) {
        this.search = searchStr;
        return
      }

      this.search = searchStr;
      this.setPageN(1)
      await this.refreshStatusesCounts();
    }, 500),

    async updateTestsViewWithStatuses(statusesList) {
      this.setPageN(1);
      this.statusesFilter = statusesList;

      await this.refreshStatusesCounts();
    },

    getTestGroupRoute(id) {
      return { name: "test-group", params: { id } }
    },

    getTestQueueRoute(id) {
      const idKey = shaKey(id.toString());
      return { name: "test-info", params: { id, idKey } };
    },

    async openExplorerDialog({ id }) {
      await this.openDialog("ExplorerDialog", { explorerId: id, value: true })
    },
  },
});
</script>

<style lang="scss">
.explorer-tests-view {
  &__owner-first-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__total-button {
    flex: 0 0 auto;

    .v-btn:not(.v-btn--round).v-size--default {
      color: var(--v-text-inverted-base);
      font-size: 12px;
    }
  }

  &__status-bar {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: center;
  }

  &__statuses-split-btn {
    flex: 1;
  }

  &__search-text-field {
    flex: 1 1 auto;
    max-width: 350px;
  }

  &__status {
    display: flex;
    gap: 3px;
    align-items: center;
  }

  &__items-per-page-select {
    width: 120px;
    flex-shrink: 0;
  }
}
</style>
