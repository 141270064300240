var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{class:{
    'ui-switch': true,
    'ui-switch--on': _setup.isChecked,
    'ui-switch--dark': _vm.dark,
    'ui-switch--disabled': _setup.attrs.disabled,
    [_setup.attrs.class]: Boolean(_setup.attrs.class),
  },attrs:{"for":_setup.attrs.id}},[_c('input',{staticClass:"ui-switch__input",attrs:{"id":_setup.attrs.id,"type":"checkbox","disabled":_setup.attrs.disabled,"hidden":""},domProps:{"checked":_setup.isChecked,"value":_setup.attrs.value},on:{"change":(e) => _setup.onChange(e)}}),_c('span',{staticClass:"ui-switch__track",attrs:{"aria-hidden":true}},[_c('span',{staticClass:"ui-switch__thumb"})]),_c('span',{staticClass:"ui-switch__label-txt"},[_vm._t("default")],2),_c(_setup.HelpButton,{attrs:{"data-help":_setup.attrs['data-help']}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }