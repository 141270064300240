<script setup>
import { computed, defineEmits, defineProps } from "vue"
import UiTable from "@/components/basic/tables/UiTable.vue"
import UiBasicTableLayout from "@/components/basic/layouts/UiBasicTableLayout.vue"
import UiSearchInput from "@/components/basic/form/UiSearchInput.vue"
import { getByKey } from "@/js/helper"
import XBtn from "@/components/basic/XBtn.vue"

// interface IBasicColumn = {
//   key: string
//   name: string
// }
// type Props = {
//   basicInfoCols?: IBasicColumn[],
//   basicInfo?: Record<string, unknown>
//   journalInfoCols: IColumn[]
//   objectInfoCols: IColumn[]
//   items: Record<string, unknown>[]
//   historyItemName: string
//   searchText: string
// }
const props = defineProps({
  basicInfoCols: {
    type: Array,
    default: () => [],
  },
  basicInfo: {
    type: Object,
    default: () => ({}),
  },
  journalInfoCols: {
    type: Array,
    required: true,
  },
  objectInfoCols: {
    type: Array,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  historyItemName: {
    type: String,
    required: true,
  },
  searchText: {
    type: String,
    default: "",
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  sortBy: {
    type: String,
    default: "",
  },
  sortDesc: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(["go-back", "update:searchText", "update:sorting" ])

const columns = computed(() => {
  return props.journalInfoCols.concat(props.objectInfoCols)
})

const searchText = computed({
  get: () => props.searchText,
  set: (value) => emit("update:searchText", value),
})

const makeSlotName = (name) => name.replace(/\./g, "-")
const getHeaderSlotName = (name) => `header(${name})`
const getCellSlotName = (name) => `cell(${name})`
</script>

<template>
  <UiBasicTableLayout class="ui-history-view">
    <template #page-headline>
      <slot name="page-headline">
        <div class="ui-history-view__page-headline">
          <XBtn icon="mdi-step-backward" text="Back" color="primary" @click="() => emit('go-back')"/>

          <h1 class="ui-history-view__headline-txt">
            History of

            <template v-if="basicInfo">
              {{ historyItemName }}
            </template>
          </h1>

          <UiSearchInput
            :model-value.sync="searchText"
            class="ui-history-view__search-input"
            is-debounced
          />
        </div>
      </slot>
    </template>

    <template #table-slot>
      <div class="ui-history-view__tables-grid">
        <div
          v-if="basicInfoCols.length"
          class="ui-history-view__basic-table-box"
        >
          <table class="ui-history-view__basic-table">
            <tr class="ui-history-view__basic-table-header-row">
              <th
                class="ui-history-view__basic-table-header-cell"
                :colspan="basicInfoCols.length"
              >
                Basic information
              </th>
            </tr>

            <tr
              v-for="col of basicInfoCols"
              :key="col.key"
              class="ui-history-view__basic-table-row"
            >
              <th class="ui-history-view__basic-table-cell  ui-history-view__basic-table-cell--header">
                <slot :name="`basic-table-head(${makeSlotName(col.key)})`">
                  {{ col.name }}
                </slot>
              </th>

              <td class="ui-history-view__basic-table-cell">
                <slot
                  v-if="basicInfo"
                  :name="`basic-table-cell(${makeSlotName(col.key)})`"
                  :col="col"
                  :cellValue="getByKey(basicInfo, col.key) || ''"
                >
                  {{ getByKey(basicInfo, col.key) || "" }}
                </slot>
              </td>
            </tr>
          </table>
        </div>

        <div class="ui-history-view__main-table-box">
          <UiTable
            :items="items"
            :columns="columns"
            :is-loading="isLoading"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sorting="({ sortBy, sortDesc }) => emit('update:sorting', { sortBy, sortDesc })"
          >
            <template #top-headers>
              <tr class="ui-history-view__top-headers-row">
                <th
                  :colspan="journalInfoCols.length"
                  class="ui-history-view__top-header-cell"
                >
                  Journal information
                </th>

                <th
                  :colspan="objectInfoCols.length"
                  class="ui-history-view__top-header-cell"
                >
                  Object information
                </th>
              </tr>
            </template>

            <template
              v-for="col of columns"
              #[getHeaderSlotName(makeSlotName(col.key))]
            >
              <div style="display: contents;" :key="col.key">
                <slot
                  :name="getHeaderSlotName(makeSlotName(col.key))"
                  :col="col"
                >
                  {{ col.name }}
                </slot>
              </div>
            </template>

            <template
              v-for="col of columns"
              #[getCellSlotName(makeSlotName(col.key))]="{ item, cellValue }"
            >
              <div style="display: contents;" :key="col.key">
                <slot
                  :name="getCellSlotName(makeSlotName(col.key))"
                  :col="col"
                  :item="item"
                  :cell-value="cellValue"
                >
                  {{ cellValue }}
                </slot>
              </div>
            </template>
          </UiTable>
        </div>
      </div>
    </template>

    <template #page-footer>
      <slot name="pagination" />
    </template>
  </UiBasicTableLayout>
</template>

<style lang="scss">
$basic-table-padding: .75rem;
$back-btn-width: 130px;
$back-btn-height: 40px;
.ui-history-view {
  $root: &;

  &__page-headline {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__back-btn {
    width: $back-btn-width;
    height: $back-btn-height;
  }

  &__headline-txt {
    flex: 1 0 auto;
    font-size: 1.25rem;
  }

  &__search-input {
    margin-top: -5px;
  }

  &__tables-grid {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1.5rem;
    height: 100%;
  }

  &__basic-table {
    width: 100%;
    border-spacing: 0;
    table-layout: auto;
    padding-bottom: $basic-table-padding;
    border-bottom: 1px solid #e0e0e0;
  }

  &__basic-table-header-cell {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__basic-table-row {
    &:nth-child(2) {
      #{$root}__basic-table-cell {
        padding-top: $basic-table-padding;
      }
    }
  }

  &__basic-table-cell {
    text-align: left;
    font-size: .875rem;

    &:first-child {
      padding-left: 1rem;
      white-space: nowrap;
    }

    &--header {
      font-weight: 500;
      padding-right: 1rem;
      width: 1%;
    }
  }

  &__basic-table-header-row,
  &__top-headers-row {
    height: 32px;
    background-color: #2b5593;
  }

  &__basic-table-header-cell,
  &__top-header-cell {
    font-size: .875rem;
    text-align: left;
    color: #fff;

    &:first-child {
      padding-left: 1rem;
    }
  }
}
</style>
