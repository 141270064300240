<script setup>
import { defineProps,  ref, inject, onMounted } from "vue";
import UiDialog from "@/components/basic/dialog/UiDialog.vue"
import VueJsonEditor from "vue-json-editor";
import XBtn from "@/components/basic/XBtn.vue"

const props = defineProps({
  jsonIn: {
    type: String,
    default: "",
  }
})

const { getCloseMethod } = inject("DialogsRoot");
const _close = getCloseMethod("JsonEditorDialog");

const prevVersion = ((() => {
  try {
    return JSON.parse(props.jsonIn);
  } catch (err) {
    return props.jsonIn;
  }
})());

const editorEl = ref(null);
const newVersion = ref(prevVersion);
const isDirty = ref(false);
const isValid = ref(true);

const close = (isNewVersion) => {
  if (isNewVersion) {
    return _close({ jsonOut: JSON.stringify(newVersion.value), isNew: newVersion.value !== prevVersion });
  }
  return _close({ jsonOut: JSON.stringify(prevVersion), isNew: false });
};

const onJsonChange = () => {
  isDirty.value = true;
  isValid.value = true;
};

const onHasError = () => {
  isValid.value = false;
};

onMounted(() => {
  const [txtarea] = editorEl.value.$el.getElementsByTagName("textarea");
  txtarea.focus();
});
</script>

<template>
  <UiDialog
    title="JSON Editor"
    full-height
    full-width
    @close-modal="() => close()"
    class="json-editor-dialog"
  >
    <template #body>
      <VueJsonEditor
        ref="editorEl"
        v-model="newVersion"
        class="json-editor-dialog__editor"
        expand-on-start
        mode="code"
        @json-change="(e) => onJsonChange(e)"
        @has-error="(e) => onHasError(e)"
      />
    </template>

    <template #footer>
      <XBtn
        text="Ok"
        color="primary"
        :disabled="!isValid || !isDirty"
        @click="() => close(true)"
      />

      <XBtn
        text="Cancel"
        color="secondary"
        text-color="white"
        @click="() => close(false)"
      />
    </template>
  </UiDialog>
</template>

<style lang="scss">
.json-editor-dialog {
  &__editor {
    height: 100%;

    & > .jsoneditor-vue {
      height: 100%;
    }
  }
}
</style>
