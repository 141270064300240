<script setup>
  import { ref, computed, defineProps, defineEmits, nextTick, useAttrs } from "vue"
  import UiInputWrapper from "@/components/basic/form/ui/UiInputWrapper.vue"
  import UiChips from "@/components/basic/form/UiChips.vue"
  import XCheckbox from "@/components/basic/XCheckbox.vue"
  import UiSearchInput from "@/components/basic/form/UiSearchInput.vue"
  import { useFloating, offset, shift, autoUpdate, flip } from "@floating-ui/vue"
  import { useClickOutside } from "@/composition/click-outside"
  import { useAsyncValidation } from "@/composition/validation/use-async-validation"

  const TAB_HINT = "Press Tab to cycle through tags."
  const PLACEMENT = "bottom"
  const OFFSET = 1

  // interface Item {
  //   id: string
  //   value: string
  // }

  // type Props = {
  //   options: (string | Item)[]
  //   label: string
  //   modelValue: (string | Item)[]
  //   allowCustomValues: boolean
  // }

  const props = defineProps({
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    allowCustomValues: {
      type: Boolean,
      default: false,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
    searchHints: {
      type: String,
      default: "",
    },
    errors: {
      type: String,
      default: "",
    },
    userInputValidator: {
      // () => Promise<string>
      type: Function,
      default: () => Promise.resolve(""),
    },
    caseSensitive: {
      type: Boolean,
      default: false,
    }
  })

  const attrs = useAttrs();

  const emit = defineEmits(["update:modelValue", "update:options"])
  const anchor = ref(null)
  const floating = ref(null)
  const { clickOutsideRoot, isOpen, toggle } = useClickOutside()
  const { floatingStyles } = useFloating(anchor, floating, {
    placement: PLACEMENT,
    middleware: [offset(OFFSET), shift(), flip()],
    whileElementsMounted: autoUpdate,
  })
  const searchPlaceholder = props.allowCustomValues ? "Search or add new" : "Search"
  const {
    model: userInput,
    isLoading,
    isValid,
    error: userInputError,
  } = useAsyncValidation("", props.userInputValidator)
  const searchInputEl = ref(null)

  const stringToItem = (option, idx) => ({
    value: option,
    id: `${idx}-${option}`,
  })

  const isOptionObject = computed(() => {
    return typeof props.options[0] === "object"
  })

  const _searchedOptions = ref([])
  const _selectOptions = computed(() => {
    if (!props.options) {
      return []
    }
    if (isOptionObject.value) {
      return props.options
    }
    return props.options.map(stringToItem)
  })
  const selectOptions = computed(() => {
    if (_searchedOptions.value.length > 0 || userInput.value.length > 0) {
      return _searchedOptions.value
    }
    return _selectOptions.value
  })
  const possibleOptions = computed(() => {
    return _selectOptions.value.map((opt) => opt.value)
  })
  const resetSearch = () => {
    userInput.value = ""
    _searchedOptions.value = []
  };

  const isMatch = (original, input) => original.toLowerCase().includes(input.toLowerCase().trim());
  const autocompleteTxt = computed(() => {
    if (!userInput.value.trim().length) {
      return ""
    }
    let part = ""
    let result = ""
    for (const opt of selectOptions.value) {
      if (isMatch(opt.value, userInput.value)) {
        part = opt.value.substring(userInput.value.length)
        break
      }
    }
    if (part.length) {
      result = `${userInput.value}${part}`
    }
    return result
  })
  const highLigtedIndex = ref(-1)
  const doSearch = (str) => {
    _searchedOptions.value = _selectOptions.value.filter((opt) => isMatch(opt.value, str))
  }
  const updateUserInput = (str) => {
    highLigtedIndex.value = -1
    if (str.length === 0) {
      resetSearch()
      return
    }
    userInput.value = str
    doSearch(str)
  }

  const chips = computed(() => {
    return (
      props.modelValue?.map((item) => {
        if (isOptionObject.value) {
          return item.value
        }
        return item
      }) || []
    )
  })

  const updateSelect = (newChips) => {
    if (isOptionObject.value) {
      emit("update:modelValue", newChips.map(stringToItem))
    } else {
      emit("update:modelValue", newChips)
    }
  }

  const updateOptions = async (...ops) => {
    if (isOptionObject.value) {
      emit("update:options", Array.from(new Set([...props.options, ...ops.map((op, i) => stringToItem(op, i))])));
    } else {
      emit("update:options", Array.from(new Set([...props.options, ...ops])));
    }
    // let's wait for the options to be updated...
    await nextTick()
    doSearch(userInput.value)
  }

  // default is case insensitive
  const compareCaseInsensitive = (a, b) => {
    return a.toLowerCase() === b.toLowerCase();
  };
  const compareCaseSensitive = (a, b) => {
    return a === b;
  }
  const addChip = async (str) => {
    if (isLoading.value || !isValid.value) {
      return
    }

    const compare = props.caseSensitive ? compareCaseSensitive : compareCaseInsensitive;

    let _val = str.trim()

    if (!_val) {
      return
    }

    if (chips.value.some((chip) => compare(chip, _val))) {
      const newChips = chips.value.filter((chip) => !compare(chip, _val))
      updateSelect(newChips)
      return
    }

    if (!props.allowCustomValues && !possibleOptions.value.some((opt) => compare(opt, _val))) {
      return
    } else if (props.allowCustomValues && !possibleOptions.value.some((opt) => compare(opt, _val))) {
      await updateOptions(_val)
    }

    if (props.caseSensitive) {
      updateSelect([...chips.value, _val])
    } else {
      const chip = _selectOptions.value.find((opt) => compare(opt.value, _val))

      chip && updateSelect([...chips.value, chip.value])
    }
  }

  const onTabPress = (dir) => {
    searchInputEl.value?.focus()
    const isUserInput = userInput.value.trim().length > 0
    const list = isUserInput ? _searchedOptions : selectOptions
    if (highLigtedIndex.value < list.value.length - 1) {
      highLigtedIndex.value += dir
      const idx = Math.min(highLigtedIndex.value, list.value.length - 1)
      userInput.value = isUserInput ? list.value[idx].value : ""
    } else {
      highLigtedIndex.value = 0
      userInput.value = isUserInput ? list.value[highLigtedIndex.value].value : ""
    }
  }

  const checkboxHandler = (isChecked, optionId) => {
    highLigtedIndex.value = -1
    const option = _selectOptions.value.find((opt) => opt.id === optionId)
    if (isChecked) {
      updateSelect([...chips.value, option.value])
    } else {
      const newChips = chips.value.filter((chip) => chip !== option.value)
      updateSelect(newChips)
    }
  }

  const onSpacePress = (e) => {
    if (highLigtedIndex.value < 0) {
      return
    }
    e.preventDefault();
    searchInputEl.value?.blur();
    addChip(selectOptions.value[highLigtedIndex.value].value);
    searchInputEl.value?.focus();
  };

</script>

<template>
  <div
    class="ui-chips-select"
    ref="clickOutsideRoot"
    @click.prevent.stop
  >
    <UiInputWrapper
      ref="anchor"
      class="ui-chips-select__input-wrapper"
      :label="label"
      :is-focused="isOpen"
      :is-not-empty="chips.length > 0"
      :errors="errors"
      @click.prevent.stop
      :data-help="attrs['data-help']"
    >
      <div
        @click.stop.prevent="() => toggle()"
        :class="{
          'ui-chips-select__chips-wrapper input': true,
          'ui-chips-select__chips-wrapper--overflow': noWrap,
        }"
      >
        <UiChips
          :no-wrap="noWrap"
          :model-value="chips"
          @update:modelValue="(newChips) => updateSelect(newChips)"
        />
      </div>

      <template #input-slot-right>
        <button
          :class="{
            'ui-chips-select__arrow-btn': true,
            'ui-chips-select__arrow-btn--open': isOpen,
          }"
          @click.prevent.stop="() => toggle()"
          type="button"
        >
          <v-icon class="ui-chips-select__arrow-ico">mdi-menu-down</v-icon>
        </button>
      </template>
    </UiInputWrapper>

    <v-fade-transition>
      <div
        ref="floating"
        v-if="isOpen"
        :style="floatingStyles"
        class="ui-chips-select__options-box"
        @click.stop
      >
        <div class="ui-chips-select__top-block">
          <div
            class="ui-chips-select__search-box"
            @keyup.enter.stop="() => addChip(userInput)"
            @keydown.space.stop="(e) => onSpacePress(e)"
            @keydown.tab.prevent.stop.exact="() => onTabPress(1)"
            @keydown.shift.tab.prevent.stop.exact="() => onTabPress(-1)"
          >
            <slot
              name="search-input"
              :modelVal="userInput"
              :label="searchPlaceholder"
              :withClear="true"
              :hints="searchHints"
              :updateModelVal="updateUserInput"
              :errors="userInputError"
              :is-loading="isLoading"
            >
              <UiSearchInput
                ref="searchInputEl"
                :label="searchPlaceholder"
                :model-value="userInput"
                with-clear
                :hints="`${TAB_HINT} ${ searchHints }`"
                @update:modelValue="(txt) => updateUserInput(txt)"
                :errors="userInputError"
                :is-loading="isLoading"
                :autocomplete-text="autocompleteTxt"
              />
            </slot>
          </div>
        </div>

        <ul class="ui-chips-select__options">
          <li
            v-for="(opt, i) of selectOptions"
            :key="opt.id"
            :class="{
              'ui-chips-select__option': true,
              'ui-chips-select__option--highlighted': highLigtedIndex === i,
            }"
          >
            <XCheckbox
              class="ui-chips-select__option-checkbox"
              :value="chips.includes(opt.value)"
              @input="(isChecked) => checkboxHandler(isChecked, opt.id)"
              :label="opt.value"
            />
          </li>
        </ul>
      </div>
    </v-fade-transition>
  </div>
</template>

<style lang="scss">
  .ui-chips-select {
    $root: &;

    position: relative;
    width: 100%;

    &__chips-wrapper {
      width: 100%;
      padding: 0 8px;
      min-height: 30px;
      margin-top: 6px;
      margin-bottom: 1px;
      cursor: pointer;

      &--overflow {
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &__arrow-btn {
      width: 24px;
      height: 24px;
      background-color: transparent;

      #{$root}__arrow-ico {
        color: var(--input-wrapper-icon-color, rgba(0,0,0,.54));
        transition: transform 0.3s;
      }

      &--open {
        #{$root}__arrow-ico {
          transform: rotate(180deg);
        }
      }
    }

    &__top-block {
      min-height: 74px;
    }

    &__search-box {
      position: absolute;
      z-index: 10;
      padding: 0 1rem 0.25rem;
      background-color: #fff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      width: 100%;
      --input-slot-width: 100%;
    }

    &__options-box {
      z-index: 8;
      border-radius: 4px;
      width: 100%;
      background-color: #fff;
      box-shadow: -2px 0 4px -1px rgba(0, 0, 0, 0.2), 2px 0 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      padding-bottom: .25rem;
    }

    & &__options {
      padding-left: 0;
    }

    &__options {
      overflow: auto;
      max-height: 300px;
      list-style: none;
    }

    &__option {
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 1.5rem;

      &::after {
        content: "";
        position: absolute;
        opacity: 0;
        inset: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.3s;
        // $primary
        background-color: #2b5593;
        pointer-events: none;
      }

      &--highlighted {
        &:after {
          opacity: .16;
        }
      }
    }

    &__option-checkbox {
      width: 100%;
    }
  }
</style>
