<script setup>
import { ref, onMounted, defineProps, inject } from "vue";
import UiDialog from "@/components/basic/dialog/UiDialog.vue"
import { getMdPreviewComponent } from "@/js/get-md-preview"
import { useHelpStore } from "@/composition/help/use-help-store";

const { MdPreviewComponent } = getMdPreviewComponent();

const props = defineProps({
  helpId: {
    type: String,
    required: true,
  }
});

const { getCloseMethod } = inject("DialogsRoot");
const _close = getCloseMethod("HelpDialog");
const { getHelpText, isLoading } = useHelpStore();

const content = ref("");

const close = () => {
  _close();
}

onMounted(async () => {
  if (!props.helpId) {
    return;
  }

  content.value = await getHelpText({ id: props.helpId });
})
</script>

<template>
  <UiDialog
    title="Help"
    size="small"
    @close-modal="() => close()"
  >
    <template #body>
      <div
        v-if="isLoading"
      >
        <span class="skeleton skeleton--first"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
        <span class="skeleton"></span>
      </div>

      <MdPreviewComponent
        v-else-if="content"
        :text="content"
      />

      <span v-else>
        {{ helpId || "Help ID is not defined" }}
      </span>
    </template>
  </UiDialog>
</template>

<style lang="scss">
.skeleton {
  display: inline-block;
  width: 100%;
  height: 24px;
  @include dark-text-skeleton();

  &--first {
    height: 32px;
    width: 42%;
  }
}

</style>
