<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router/composables';
import { useFiberNet } from "@/composition/menu/use-fiber-net";
import SuperAdminMenu from '@/components/specific/menu/SuperAdminMenu.vue';
import requests from '@/js/requests';
import { tokenRefresher } from '@/auth-tools';
import XSwitch from '@/components/basic/XSwitch.vue';
import { useHelpStore } from "@/composition/help/use-help-store";
import { testInfoAdminInfoChannel } from "@/store/channels/test-info-admin-info"

const ContextSwitcher = () => import(/* webpackChunkName: "context-switcher-dialog" */ "@/components/specific/menu/ContextSwitcher.vue");

const store = useStore();
const route = useRoute();

const {
  isEnabled: isFiberNetEnabled,
  toggleFiberNet,
  replaceData
} = useFiberNet();

const { 
  areAllHelpBtnsVisible,
  toggleHelpBtnsVisibility
} = useHelpStore();

const contextSwitcher = ref(false);

const userId = computed(() => store.state.user.id);
const isSuperAdmin = computed(() => store.state.permissions.superAdmin);
const showAdminInfoSwitch = computed(() => route.name === 'test-info' || route.name === 'explorers');
const testInfoAdminInfo = computed(() => store.state.testInfoAdminInfo);
const logging = computed(() => store.state.logging);
const organizationName = computed(() => {
  const name = store.state.organization.name;
  return isFiberNetEnabled.value ? replaceData(name) : name;
});
const projectName = computed(() => {
  const name = store.state.project.name;
  return isFiberNetEnabled.value ? replaceData(name) : name;
});
const userFirstName = computed(() => store.state.user.firstName);

const logOut = () => {
  requests.phpServiceGetRequest('?f=logout', null, null, tokenRefresher.logout);
};

const toggleAdminInfo = async ({ isClick = false } = {}) => {
  await store.dispatch("toggleTestInfoAdminInfo", { isClick });
};
const onAdminInfoSwitch = async () => {
  await toggleAdminInfo({ isClick: true });

  if (route.name === "test-info") {
    const n_id = route.params.id;
    const n_id_key = route.params.idKey;
    await store.dispatch("fetchTestInfoData", { n_id, n_id_key });
  }
};

const toggleLogging = () => {
  store.commit('toggleLogging');
};

onMounted(() => {
  testInfoAdminInfoChannel.addCallback(toggleAdminInfo);
});
onUnmounted(() => {
  testInfoAdminInfoChannel.removeCallback(toggleAdminInfo);
});
</script>

<template>
  <div class="system-bar">
    <v-system-bar color="system-bar">
      <template v-if="userId">
        <template v-if="isSuperAdmin">
          <XSwitch
            title="Toggle Help Buttons"
            :value="areAllHelpBtnsVisible"
            @input="() => toggleHelpBtnsVisibility()"
          />

          <XSwitch
              v-if="showAdminInfoSwitch"
              title="Admin Info"
              :value="testInfoAdminInfo"
              @input="onAdminInfoSwitch"
          />

          <XSwitch
            title="Fiber Net"
            :value="isFiberNetEnabled"
            @input="(v) => toggleFiberNet(v)"
          />

          <XSwitch title="Service Log" :value="logging" @input="toggleLogging"/>

          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon x-small v-bind="attrs" v-on="on" class="super-admin-menu-icon">
                <v-icon color="white">mdi-cog-transfer-outline</v-icon>
              </v-btn>
            </template>

            <v-card>
              <SuperAdminMenu/>
            </v-card>
          </v-menu>

          <div class="white--text">|</div>
        </template>

        <v-btn x-small color="system-bar" elevation="0" class="white--text" @click="contextSwitcher = true">
          Organization: {{ organizationName }} | Project: {{ projectName }}
        </v-btn>

        <ContextSwitcher
            v-if="contextSwitcher"
            v-model="contextSwitcher"
        />

        <div class="white--text">|</div>

        <v-btn x-small color="system-bar" elevation="0" class="white--text" @click="logOut">
          Log out {{ userFirstName }}
        </v-btn>

        <div class="white--text">|</div>

        <a href="https://service.acctopus.com" target="_blank">
          <v-btn x-small color="system-bar" elevation="0" class="white--text">
            Get support
          </v-btn>
        </a>
      </template>
    </v-system-bar>
  </div>
</template>

<style scoped>
.system-bar {
  display: flex;
  justify-content: flex-end;
}

.super-admin-menu-icon {
  margin-right: 6px;
}
</style>
