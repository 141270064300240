<template>
  <div>
    <FormDialog
        :value="value"
        @input="() => close()"
        title="Select Device Pool"
        save-text="Assign"
        save-icon="mdi-view-grid-plus"
        save-color="primary"
        width="350"
        @save="confirmDialog = true"
    >
      <template #dialog-content>
        <XSelect
            v-model="devicePool"
            label="Device Pool"
            :items="devicePools"
            item-text="name"
            item-value="id"
            required
        />
      </template>
    </FormDialog>

    <YesNoDialog
        v-model="confirmDialog"
        text="This action will transfer the Explorer's status to the starting point of the new Business Flow. All existing Business Flow information will be erased. Do you want to proceed?"
        title="Confirm"
        width="350"
        @yes="assign"
    />

    <LoadingDialog v-model="loading"/>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import XSelect from '@/components/basic/XSelect.vue';
import { cockpitExplorerService } from "@/api";
import FormDialog from '@/components/extended/FormDialog.vue';
import YesNoDialog from '@/components/extended/YesNoDialog.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';

export default defineComponent({
  name: "AssignToDevicePoolDialog",

  components: {
    LoadingDialog,
    YesNoDialog,
    FormDialog,
    XSelect,
  },

  props: {
    value: Boolean,
    explorerIds: Array,
  },

  setup() {
    const { getCloseMethod } = inject("DialogsRoot");
    const closeMethod = getCloseMethod("AssignToDevicePoolDialog");

    return {
      closeMethod,
    };
  },

  data() {
    return {
      devicePool: 0,
      devicePools: [],
      confirmDialog: false,
      loading: false,
    };
  },

  async created() {
    this.devicePools = await cockpitExplorerService.v1.getDevicePools();
  },

  methods: {
    close({ isAssigned = false } = {}) {
      if (this.closeMethod) {
        this.closeMethod({ isAssigned });
      } else {
        this.$emit('input', false);
        this.$emit('assigned');
      }
    },

    async assign() {
      this.loading = true;
      try {
        await cockpitExplorerService.v1.assignToDevicePool({ explorerIds: this.explorerIds, devicePoolId: this.devicePool });
      } finally {
        this.loading = false;
        this.close({ isAssigned: true });
      }
    },
  },
});
</script>

<style scoped>

</style>
