<template>
  <div style="display: contents;">
    <XDataTable
      class="previous-tests-view"
      title="Previous Tests"
      show-total
      :headers="headers"
      :items-request="getPreviousTestStatus"
      :items-request-params="itemsRequestParams"
      :select-actions="selectActions"
      :refresh="30"
      @refresh="() => refreshTestsStatusesCount()"
      @sorting-change="(header) => updateSortingData(header.value)"
      no-count
    >
      <template #time-range-slot>
        <DateTimeRange
          :value="range"
          @input="(newRange) => handleRangeUpdate(newRange)"
        />
      </template>

      <template #search-input-slot>
        <XTextField
          class="previous-tests-view__search-text-field"
          :value="search"
          clearable
          label="Search"
          append-icon="mdi-magnify"
          @input="(searchStr) => handleSearchUpdate(searchStr)"
        />
      </template>

      <template #above-table>
        <div class="previous-tests-view__above-table-block">
          <div class="previous-tests-view__status-bar">
            <XBtn
              class="previous-tests-view__total-button"
              :text="statusTotal.text"
              height="25"
              :color="statusesFilter.length ? `${statusTotal.color} lighten-3` : `${statusTotal.color}`"
              elevation="0"
              @click="() => selectTotalStatuses()"
            />

            <SplitButton
              class="previous-tests-view__statuses-split-btn"
              :value="statusesFilter"
              :values="statusesCounts"
              :splits="statusesRepr"
              :total="statusesCounts?.total || 0"
              @input="(statusesList) => updateTestsViewWithStatuses(statusesList)"
              multiple
            />
          </div>
        </div>
      </template>

      <template #[`item.testInfo`]="{row}">
        <TestInfoButton :test-id="row.id" :test-id-key="shaKey(row.id)"/>
      </template>

      <template #[`item.testCase.name`]="{value, row}">
        <SearchRouterLink
          v-model="search"
          :text="value"
          :to="getTestInfoPath(row.id)"
        />
      </template>

      <template #[`item.status`]="{value}">
        <div class="previous-tests-view__group-test-status">
          <v-icon :color="testStatus[value].color">{{ testStatus[value].icon }}</v-icon>

          {{ testStatus[value].text }}
        </div>
      </template>

      <template #[`item.subscriber`]="{value,row}">
        <SubscriberExplorerLabel
            :type="row.type"
            :value="value"
        />
      </template>

      <template #[`item.owner`]="{ value }">
        <v-tooltip top>
          <template #activator="{ attrs, on }">
            <span
              class="previous-tests-view__owner-first-name"
              v-bind="attrs"
              v-on="on"
            >
              {{ value.firstName }}
            </span>
          </template>

          <span>
            {{ `${value.firstName} ${value.lastName}` }}
          </span>
        </v-tooltip>
      </template>

      <template #pagination-slot="{ metadata }">
        <PaginationBlock
          :total-count="metadata.totalCount || 0"
          :current-page="page"
          :items-per-page="metadata.perPage || 0"
          @update:current-page="(pageN) => setPageN(pageN)"
        />
      </template>

      <template #items-per-page-slot>
        <XSelect
          class="previous-tests-view__items-per-page-select"
          :items="ITEMS_PER_PAGE_OPTIONS_DEFAULT"
          :value="itemsPerPage"
          @input="(_itemsPerPage) => updateItemsPerPage(_itemsPerPage)"
          label="Items per page"
          required
          :autocomplete="false"
        />
      </template>
    </XDataTable>

    <LoadingDialog :value="loading"/>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, computed, ref, provide } from "vue"
import { useRoute } from "vue-router/composables"
import XTextField from '@/components/basic/XTextField.vue';
import XDataTable from '@/components/basic/XDataTable.vue';
import TestInfoButton from '@/components/specific/TestInfo/TestInfoButton.vue';
import SubscriberExplorerLabel from '@/components/specific/SubscriberExplorerLabel.vue';
import {shaKey} from '@/js/helper';
import testStatus from '@/cfg/testStatus.json';
import testCaseTypes from '@/cfg/testCaseTypes.json';
import XBtn from '@/components/basic/XBtn.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import SearchRouterLink from '@/components/basic/SearchRouterLink.vue';
import SplitButton from '@/components/basic/SplitButton';
import DateTimeRange from '@/components/basic/DateTimeRange.vue';
import XSelect from '@/components/basic/XSelect.vue';
import cockpitTestStatusService from "@/js/services/CockpitTestStatusService";
import { unixToDateTimeString } from '@/js/general';
import { useTimeRangeSelectWithRouter } from "@/composition/filtering-components/use-time-range-select"
import { useStatusBarSelectWithRouter } from "@/composition/filtering-components/use-status-bar-select"
import { useSearchInputWithRouter, MIN_SEARCH_LENGTH } from "@/composition/filtering-components/use-search-input-w-router"
import { useTableSettingsWithRouter, ITEMS_PER_PAGE_OPTIONS_DEFAULT } from "@/composition/tables/use-table-settings"
import { usePaginationWithRouter } from "@/composition/filtering-components/use-pagination-w-router"
import { useTableSortingWithRouter } from "@/composition/filtering-components/use-sorting-w-router"
import { debounce } from "lodash-es"
import { useZipFileDownloader } from "@/composition/zip-files/use-zip-file-downloader";

// Termporary solution
// TODO: remove this function after using new table components
function getPreviousTestStatus(
  status,
  testQueueIds,
  search,
  itemsPerPage,
  sortBy,
  descending,
  rangeFrom,
  rangeTo,
  page,
  params,
  then,
  error
) {
  params["search"] = search
  params["items-per-page"] = itemsPerPage
  params["sortBy"] = sortBy
  params["descending"] = descending
  params["from"] = rangeFrom
  params["to"] = rangeTo
  params["page"] = page
  cockpitTestStatusService.getPreviousTestStatus(status, testQueueIds, params, then, error)
}

const TABLE_SETTINGS_KEY = "previous-tests-view"

export default defineComponent({
  name: 'PreviousTestsView',

  components: {
    SubscriberExplorerLabel,
    SearchRouterLink,
    LoadingDialog,
    XBtn,
    TestInfoButton,
    XDataTable,
    SplitButton,
    DateTimeRange,
    XTextField,
    XSelect,
    PaginationBlock,
  },

  setup() {
    const route = useRoute()
    const { downloadZipFile } = useZipFileDownloader();
    const {
      statusesCounts,
      statusesFilter,
      statusesRepr,
      statusTotal,
      statusesAsParamString,
      selectTotalStatuses,
    } = useStatusBarSelectWithRouter({ multiple: true })
    const {
      range,
      rangeAsReqParams,
    } = useTimeRangeSelectWithRouter()
    const {
      search,
      searchAsReqParam,
    } =  useSearchInputWithRouter()

    const {
      page,
      setPageN,
    } = usePaginationWithRouter()

    const {
      settings: tableSettings,
      updateItemsPerPage: _updateItemsPerPage,
    } = useTableSettingsWithRouter({ key: TABLE_SETTINGS_KEY, defaultSettings: { itemsPerPage: 25 } })
    const itemsPerPageFromUrl = ref(route.query.itemsPerPage ? parseInt(`${route.query.itemsPerPage}`) : null)
    /**
     * @param {number} numberOfItems
     */
    const updateItemsPerPage = (numberOfItems) => {
      _updateItemsPerPage(numberOfItems)
      if (itemsPerPageFromUrl.value) {
        itemsPerPageFromUrl.value = null
      }
    }

    const itemsPerPage = computed(() => {
      if (itemsPerPageFromUrl.value) {
        return itemsPerPageFromUrl.value
      }
      return tableSettings.value.itemsPerPage
    })

    const {
      sortingData,
      updateSortingData,
    } = useTableSortingWithRouter()

    async function getPreviousTestStatusCounts() {
      const params = {
        from: rangeAsReqParams.value.from,
        to: rangeAsReqParams.value.to,
        search: search.value,
      };

      try {
        return await cockpitTestStatusService.getPreviousTestStatusCounts(null, params)
      } catch(error) {
        console.error("[getPreviousTestStatusCounts]", error)
      }
    }

    const refreshTestsStatusesCount = async () => {
      statusesCounts.value = await getPreviousTestStatusCounts()
    }

    onBeforeMount(async () => {
      await refreshTestsStatusesCount()
    })

    provide("x-data-table-refactoring", {
      sortingData,
    })

    return {
      page,
      setPageN,
      statusesCounts,
      statusesFilter,
      statusesRepr,
      statusesAsParamString,
      statusTotal,
      selectTotalStatuses,
      range,
      rangeAsReqParams,
      sortingData,
      updateSortingData,
      itemsPerPage,
      updateItemsPerPage,
      search,
      getPreviousTestStatusCounts,
      refreshTestsStatusesCount,
      testStatus,
      searchAsReqParam,
      getPreviousTestStatus,
      downloadZipFile,
      ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    }
  },

  data() {
    return {
      groupName: '',
      groupId: 0,
      loading: false,
      headers: Object.freeze([
        {
          value: 'testInfo',
          width: 50,
        },
        {
          text: 'Test Name',
          value: 'testCase.name',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          formatter: value => testCaseTypes[value],
          width: 100,
        },
        {
          text: 'Subscriber/Explorer',
          value: 'subscriber',
          width: 200,
          sortable: true,
        },
        {
          text: 'Owner',
          value: 'owner',
          width: 120,
        },
        {
          text: 'Start Time',
          value: 'startTime',
          formatter: unixToDateTimeString,
          width: 142,
          sortable: true,
        },
        {
          text: 'End Time',
          value: 'endTime',
          formatter: unixToDateTimeString,
          width: 142,
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          width: 183,
          sortable: true,
        },
        {
          text: 'Result Info',
          value: 'resultInfo',
          sortable: true,
        },
        {
          text: 'Audit',
          value: 'audit',
        }
      ]),
      selectActions: Object.freeze([
        {
          icon: 'mdi-folder-zip',
          text: 'Detailed Report (zip)',
          click: async (selectedItems) => {
            this.loading = true;

            try {
              await this.downloadZipFile(selectedItems);
            } finally {
              this.loading = false;
            }
          }
        },
      ]),
    };
  },

  computed: {
    itemsRequestParams() {
      return [
        this.statusesAsParamString,
        null,
        this.searchAsReqParam,
        this.itemsPerPage,
        this.sortingData.sortBy,
        this.sortingData.descending,
        this.rangeAsReqParams.from,
        this.rangeAsReqParams.to,
        this.page,
      ];
    },
  },

  methods: {
    shaKey,
    handleSearchUpdate: debounce(async function(searchStr) {
      if ((searchStr.length && searchStr.length < MIN_SEARCH_LENGTH) || this.search?.length < MIN_SEARCH_LENGTH && !searchStr.length) {
        this.search = searchStr;
        return
      }

      this.search = searchStr;
      this.setPageN(1);
      await this.refreshTestsStatusesCount()
    }, 500),

    async handleRangeUpdate(value) {
      this.range = value;
      this.setPageN(1);
      await this.refreshTestsStatusesCount()
    },

    async updateTestsViewWithStatuses(statusesList) {
      this.setPageN(1);
      this.statusesFilter = statusesList;

      await this.refreshTestsStatusesCount();
    },

    getTestInfoPath(id) {
      const idKey = shaKey(id.toString());
      return { name: "test-info", params: { id, idKey  } };
    },
  },
});
</script>

<style lang="scss">
.previous-tests-view {
  &__owner-first-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__total-button {
    flex: 0 0 auto;

    .v-btn:not(.v-btn--round).v-size--default {
      color: var(--v-text-inverted-base);
      font-size: 12px;
    }
  }

  &__status-bar {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: center;
  }

  &__statuses-split-btn {
    flex: 1;
  }

  &__search-text-field {
    flex: 1 1 auto;
    max-width: 350px;
  }

  &__above-table-block {
    display: flex;
    gap: 4px;
  }

  &__group-test-status {
    display: flex;
    gap: 3px;
    align-items: center;
  }

  &__items-per-page-select {
    width: 120px;
    flex-shrink: 0;
  }
}
</style>

